<template>
  <div class="business">
    <div class="business_head">
      <div class="business_head_title">药材合集 > {{ tabList[tabIndex] }}</div>
    </div>
    <div class="business_list">
      <div class="business_list_left">
        <div class="business_list_left_head">道地药材</div>
        <div class="business_list_left_tabList">
          <div class="business_list_left_tabList_tab" :class="index == tabIndex ? 'isActive' : ''"
            @click="setTabIndex(index)" v-for="(item, index) in tabList" :key="item">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="business_list_right" v-if="medicineDetail.medicineName">
        <div class="business_list_right_head">
          <img :src="require('../../static/image/' + medicineDetail.medicineName + '.jpg')" width="530px" />
          <div class="business_list_right_head_in">
            <b class="business_list_right_head_in_name">{{ medicineDetail.medicineName }}</b>
            <div class="margin-20">
              <b>中药名称：</b><span>{{ medicineDetail.medicineName }}</span>
            </div>
            <div class="margin-20">
              <b>别 名：</b><span>{{ medicineDetail.medicineOtherName }}</span>
            </div>
            <div class="margin-20">
              <b>中药学名（拉丁名）：</b><span>{{ medicineDetail.medicineName}}</span>
            </div>
            <div class="margin-20">
              <b>产区分布：</b><span>{{ medicineDetail.medicineOriginPlace }}</span>
            </div>
            <div class="margin-20">
              <b>科：</b><span>{{ medicineDetail.medicineFamilies }}</span>
            </div>
            <div class="margin-20">
              <b>功 效：</b><span>{{ medicineDetail.medicineEffect }}</span>
            </div>
          </div>
        </div>
        <div class="business_list_right_line"></div>
        <div class="business_list_right_content margin-20" v-html="medicineDetail.medicineDetail"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "business",
  data() {
    return {
      tabList: [
        "关药",
        "北药",
        "怀药",
        "维药",
        "浙药",
        "海药",
        "南药",
        "蒙药",
        " 川药",
        "云药",
        "贵药",
        "广药",
        "淮药",
        "藏药",
        "秦药",
      ],
      medicineDetail: {},
      tabIndex: 0,
      query: {
        page: 0,
        size: 8,
        total: 0,
      },
      id: "",
      medicineDetailImg: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.tabIndex = this.$route.query.tabIndex;
    this.getInfo()
    window.scroll(0, 0);
    this.getWXInfo()
  },
  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪道地药材', // 分享标题
            desc: '药材合集', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: '	https://www.zoneyu.cc/img/%E8%BF%9C%E5%BF%97.3fda36cd.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    getInfo() {
      axios.get(
        `https://admin.zoneyu.cn/cms/api/app/medicine/detail/` + this.id
      ).then(async (res) => {
        this.medicineDetail = res.data.data
      });
    },
    setTabIndex(index) {
      this.tabIndex = index;
      this.$router.push({
        path: "/business",
        query: { tabIndex: this.tabIndex },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.margin-20 {
  margin-top: 20px;
}

.business {
  border-top: 10px solid #18511c;
}

.business_head {
  width: 1280px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 400;
  color: #858585;
  line-height: 20px;

  .business_head_title {
    margin-top: 40px;
  }
}

.business_list {
  display: flex;
  width: 1280px;
  margin: 0 auto;
  margin-top: 30px;

  .business_list_left {
    width: 240px;
    margin-right: 40px;

    .business_list_left_head {
      width: 240px;
      height: 64px;
      background: #18511c;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 64px;
      letter-spacing: 15px;
      text-align: center;
    }

    .business_list_left_tabList_tab {
      width: 240px;
      height: 64px;
      border-bottom: 1px solid #f5f5f7;
      border-left: 1px solid #f5f5f7;
      border-right: 1px solid #f5f5f7;
      line-height: 64px;
      text-align: center;
      cursor: pointer;
      position: relative;

      .isActive {
        background: #f9f9f7;
      }
    }

    .isActive::after {
      content: "";
      position: absolute;
      top: 25px;
      left: 170px;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      /*以下四个样式对应四种三角形，任选其一即可实现*/
      border-left-color: #18511c;
    }
  }

  .business_list_right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;

    .business_list_right_head {
      display: flex;
      justify-content: space-between;

      .business_list_right_head_in {
        margin-left: 50px;

        .business_list_right_head_in_name {
          font-size: 25px;
          color: #d3000f;
        }
      }
    }

    .business_list_right_line {
      margin-top: 40px;
      height: 1px;
      width: 100%;
      background-color: #ddd;
    }

    .business_list_right_content {
      letter-spacing: 1px;
      margin-bottom: 40px;

      b {
        font-size: 22px;
      }
    }
  }
}

.business_pagination {
  margin: 0 auto;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 64px;
  padding-left: 280px;
  width: 1000px;
}

::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #354a32;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}

::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}

::v-deep .el-pager li.active+li {
  border: 1px solid #e5e5e5;
}
</style>
